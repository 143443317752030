import { Link } from "gatsby";
// UI
import H5 from "../../../../UI/Titles/H5";
// Style
import styled, { css } from "styled-components";
import Colors from "../../../../../assets/styles/Colors";
import Fonts from "../../../../../assets/styles/Fonts";
import Typography from "../../../../../assets/styles/Typography";
import { tablet } from "../../../../../assets/styles/Responsive";

const Wrapper = styled.div`
  
`;

const Head5 = styled(H5)`
  margin-bottom: 20px;
  color: ${Colors.white};
`;

const NavWrap = styled.div`
  display: ${props => props.hascontacts ? 'flex' : 'block'};;
`;

const Nav = styled.div`
  display: ${props => props.hasnavigation ? (props.hascontacts ? 'block' : 'flex') : 'none'};
  flex-wrap: wrap;
  flex-shrink: 0;
  width: ${props => props.hascontacts ? '50%' : '100%'};

  @media (max-width: ${tablet}) {
    ${props => props.hascontacts ? 'display: block' : ''};
    & > div {
      ${props => props.hascontacts ? 'margin-left: 0!important' : ''};
    }
    ${props => props.hasnavigation ? '' : 'display: none'};
  }
`;

const LinkItemWrap = styled.div`
  margin-bottom: 12px;
  width: ${props => props.hascontacts ? '100%' : 'calc(50% - 25px)'};

  &:nth-child(2n) {
    margin-left: ${props => props.hascontacts ? '0' : '50px'};
  }
`;

const LinkItem = styled(Link)`
  display: inline-block;
  color: ${Colors.white};
  text-decoration: none;
  font-size: 12px;
  line-height: 12px;
  font-family: ${Fonts.IBMRegular};
  ${Typography.underlineLink};

  &:after {
    height: 1px;
    bottom: -2px;
    background-color: ${Colors.white};
  }
`;

const Contact = styled.div`
  flex-shrink: 0;
  @media (min-width: ${tablet}) {
    padding-left: 15px;
  }
`;

const contacts = css`
  color: ${Colors.white};
  font-size: 12px;
  line-height: 16px;
  text-decoration: none;
  display: block;
  font-family: ${Fonts.IBMRegular};
`;

const ContactItem = styled.a`
  ${contacts};
`;

const ContactItemAddress = styled.div`
  ${contacts};
  max-width: 160px;
  margin-top: 20px;
`;

export {
  Wrapper,
  Head5,
  NavWrap,
  Nav,
  LinkItemWrap,
  LinkItem,
  Contact,
  ContactItem,
  ContactItemAddress,
}