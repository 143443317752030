import * as React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

//Images
import logo from '../../../assets/images/favicon.ico';
import sharepic from '../../../assets/images/skyline2.png';

function Seo({children, location, description, title, image, keywords, schemaMarkup}) {

  const { site } = useStaticQuery(
    graphql`
      query {
        site: strapiSeo {
          siteMetadata: seo {
            metaTitle
            metaDescription
            keywords
          }
        }
      }
    `
  )

  return (
    <Helmet>
      <html lang="en"/>
      <title>{title}</title>
      <link rel="icon" type="image/svg+xml" href={logo}/>
      <meta name="viewport" content="width=device-width,initial-scale=1.0"/>
      <meta charSet="utf-8" />
      <meta name="description" content={site.siteMetadata. metaDescription} />

      {location && <meta property="og:url" content={location.href} />}
      <meta property="og:image" content={image || sharepic} />
      <meta property="og:title" content={title} key="ogtitle" />
      <meta 
          property="og:site_name" 
          content={site.siteMetadata.metaTitle}
          key="ogsitename"    
      />
      <meta property="og:description" content={description}/>
      <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
      </script>
      {children}
    </Helmet>
  )
}

export default Seo