import React from 'react';
import PropTypes from 'prop-types';
// Components
import Container from '../../layouts/Container';
import Header from '../../templates/Header';
import Footer from '../../templates/Footer';
import Seo from '../../templates/Seo/seo';
// Styles
import { GlobalStyle } from '../../../assets/styles/GlobalStyles';
import 'normalize.css';
import { FiltersProvider } from "../../../contexts/filters"

const MainLayout = ({ children, noNavbar = false }) => {
  // const data = PageQuery();
  return (
    <>
      <GlobalStyle />
      <FiltersProvider>
        {!noNavbar && (
          <Container>
            <Header absolute />
          </Container>
        )}
        <main>{children}</main>
        <Footer />
      </FiltersProvider>
    </>
  );
};

MainLayout.defaultProps = {
  noNavbar: false,
};

MainLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  noNavbar: PropTypes.bool,
};

export default MainLayout;
