import styled, { css } from "styled-components";
import { Link } from "gatsby";
// UI
import H5 from "../../UI/Titles/H5";
import Typography from "../../../assets/styles/Typography";
// Style
import Colors from "../../../assets/styles/Colors";
import Fonts from "../../../assets/styles/Fonts";
import { tabletXl, tablet } from "../../../assets/styles/Responsive";
import Social from "../../UI/Social";

const activeItemStyle = `&:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: ${Colors.green};
  transition: opacity .2s, transform .2s;
  opacity: 1;
  transform: scale(1);
  transform-origin: center;
`

const Wrapper = styled.div`
  position: relative;
  width: 100%;

    .desktop{
        display: block;
    }

  .mobile{
        display: none;
    }

  @media (max-width: ${tablet}) {
    .mobile{
        display: block;
    }

    .desktop{
        display: none;
    }
  }
`;

const Header = styled.header`
  position: ${props => props.absolute ? 'absolute' : 'relative'};
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 25px 0;
  z-index: 99;

  @media (max-width: ${tablet}) {
    padding: 20px 0;
  }
`;

const HeaderTop = styled.div`
  flex-shrink: 0;

  @media (max-width: ${tablet}) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: ${({ isOpened }) => isOpened ? 'flex-end' : 'space-between'};
  }
`;

const Logo = styled(Link)`
    &.mobile{
        display: none;
    }
  
  div > div {
    background: transparent !important;
  }

  @media (max-width: ${tablet}) {
    visibility: ${({ isOpened }) => isOpened ? 'hidden' : 'visible'};

    &.mobile{
        display: inline-block;
    }

    &.desktop{
        display: none;
    }
  }
`;

const NavWrapMobile = css`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 100vh;
  overflow-y: auto;
  display: block;
  background: ${Colors.green};
  z-index: 2;
`;

const NavWrap = styled.div`
  display: flex;
  align-items: center;
  //z-index: 1;

  @media (max-width: ${tablet}) {
    display: none;
    ${({ isOpened }) => isOpened ? NavWrapMobile : ''}; 
    max-width: 100vw;
  }
`;

const Nav = styled.nav`
  @media (max-width: ${tablet}) {
    margin-top: 80px;
    margin-bottom: 220px;
    border-top: 1px solid #2C443F;
  }
`;

const Navigation = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;

  @media (max-width: ${tablet}) {
    display: block;
    margin-right: 0;
  }
`;

const NavLi = styled.li`
  position: relative;
  @media (min-width: ${tablet}) {
    background: ${props => props.cta ? 'var(--darkgreen)' : 'none'};
    color: ${props => props.cta ? 'white' : 'var(--darkgreen)'};
  }
  @media (max-width: ${tablet}) {
    max-width: 100vw;
    padding: 20px 0px;
    border-bottom: 1px solid #2C443F;
    background-color: ${props => props.openmenu || props.openeduc ? "var(--darkestgreen)" : "var(--darkgreen)"};
    padding-bottom: ${props => props.openmenu || props.openeduc ? "0"  : "25px"};
    //padding-top: ${props => props.openeduc ? "0" : '20px'}
  }
`;

const NavItem = css`
  position: relative;
  margin: 0 10px;
  padding: 5px 10px;
  color: ${Colors.green};
  text-decoration: none;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 18px;
  font-family: ${Fonts.DrukBold};
  cursor: pointer;

  @media (max-width: ${tablet}) {
    color: ${Colors.white};
    padding: 0;
    font-size: 30px;
  }
`;

const NavSubMenu = styled.div`
  position: absolute;
  font-family: ${Fonts.DrukBold};
  left: 0;
  margin-top: 15px;
  //padding: 10px 20px;
  min-width: 310px;
  opacity: 0;
  visibility: hidden;
  display: none;
  background-color: ${Colors.greenLight};
  transition: opacity .2s linear;
  z-index: 3;

  &:before {
    content: '';
    position: absolute;
    top: -15px;
    left: 0;
    display: block;
    width: 100%;
    height: 15px;
  }

  @media (max-width: ${tablet}) {
    position: static;
    opacity: 1;
    display: ${props => (props.openmenu ? "inline-block" : "none")};
    visibility: ${props => (props.openmenu ? "visible" : "hidden")};
    background-color: var(--darkergreen);
    width: 100%;
    transition: max-height .2s ease-out;
    transition: max-height .2s ease-in;
    //max-height: ${props => (props.openmenu ? "auto" : "0")};
    
    z-index: 100000;
  }
`;

const NavSubLink = styled(Link)`
  ${Typography.underlineLink};
  display: block;
  text-decoration: none;
  @media (max-width: ${tablet}) {
    color: white;
  }
`;

const NavSubItem = styled.h5`
  color: ${Colors.green};
  ${(props) =>  props.isCurrent ? activeItemStyle : ''};
  text-transform: uppercase;
  @media (max-width: ${tablet}) {
    color: white;
    font-size: 25px;
    border-bottom: 1px solid #728F88;
  }
`;
const NavSubSubMenu = styled.div`
  height: 100%;
  width: 100%;
  display: none;
  @media (max-width: ${tablet}) {
    position: static;
    opacity: 1;
    display: ${props => (props.openmenu ? "inline-block" : "none")};
    visibility: ${props => (props.openmenu ? "visible" : "hidden")};
    background-color: var(--darkergreen);
    width: 100%;
    transition: max-height .2s ease-out;
    transition: max-height .2s ease-in;
    max-height: ${props => (props.openmenu ? "auto" : "0")};
    
    z-index: 100000;
  }
`;

const NavSubSubItem = styled.h4`
  font-family: 'IBM Plex Mono Regular';
  margin: 15px 20px;
  margin-left: 10px;
  font-size: 20px;
  color: white;
`;

const NavItemWrap = styled.div`
  @media (min-width: ${tablet}) {
    &:hover ${NavSubMenu} {
      opacity: 1;
      visibility: visible;
      display: block;
    }
  }
  @media (max-width: ${tablet}) {
    background-color: ${props => props.openmenu ? "var(--darkestgreen)" : "var(--darkgreen)"};
  }
  
`;

const NavArrow = styled.div`
  display: none;
  margin-left: 20px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0 10px;
  border-color: #ffffff transparent transparent transparent;
  transition: transform .1s linear;

  @media (max-width: ${tablet}) {
    font-size: 30px;
    line-height: 42px;
    //display: inline-block;
    display: block;
    transform: ${props => props.openmenu ? "rotate(180deg)" : "rotate(0deg)"};
  }
`;

const NavLabelLink = styled.a`
  ${NavItem};
  ${Typography.underlineLink};
  display: flex;
  align-items: center;
  margin: 0px 10px;

  &:hover ${NavArrow} {
    transform: rotate(180deg);
  }

  @media (max-width: ${tablet}) {
    font-size: ${props => (props.small ? "20px" : "30px")};
    line-height: 42px;
    padding: ${props => (props.small ? "10px 0px" : "0px")};
    justify-content: space-between;
    

    &.includemobile{
        display: flex;
        padding: 20px 0px;
    }
  }
`;

const NavLabel = styled.div`
  ${NavItem};
  ${Typography.underlineLink};
  display: flex;
  align-items: center;
  margin: 0px 10px;
  color: ${props => props.cta ? 'white' : 'var(--darkgreen)'};
  

  &:hover ${NavArrow} {
    transform: rotate(180deg);
  }

  &.mobilelabel{
    display: none;
  }

  @media (max-width: ${tablet}) {
    font-size: ${props => (props.small ? "20px" : "30px")};
    line-height: 42px;
    padding: ${props => (props.small ? "10px 0px" : "0px")};
    justify-content: space-between;
    display: flex;

    &.mobilelabel{
        display: flex;
    }
  }
`;

const NavLink = styled(Link)`
  ${NavItem};
  ${Typography.underlineLink};

  ${(props) =>  props.isCurrent ? activeItemStyle : ''};
  
  display: flex;

  @media (max-width: ${tablet}) {
    
    font-size: 30px;
    line-height: 42px;
  }
`;

const SocialWrap = styled(Social)`
  @media (max-width: ${tablet}) {
    display: inline-flex;
  }
`;

export {
  Wrapper,
  Header,
  HeaderTop,
  Logo,
  NavWrap,
  Nav,
  Navigation,
  NavLi,
  NavItem,
  NavSubMenu,
  NavSubLink,
  NavSubItem,
  NavSubSubItem,
  NavItemWrap,
  NavLabel,
  NavLabelLink,
  NavLink,
  NavArrow,
  SocialWrap,
  NavSubSubMenu,
}