import React, { useEffect, useState, } from 'react';
import styled from 'styled-components';

import { AiOutlinePlus } from "react-icons/ai";
import { MOBILE_BREAKPOINT } from '../../styles/breakpoints';

const Wrapper = styled.div`
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1.5vh;
    border-top: 1px solid rgba(68, 105, 97, 0.5);

    font-family: IBM Plex Mono Regular;

    .section{
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: space-between;

        h3{
            font-family: IBM Plex Mono Bold;
            color: var(--darkgreen);
            text-transform: capitalize;
            font-size: max(2.5vh, 18px);
            margin: 10px;
        }

        p{
            margin: 1vh 7vh 1vh 0vh;
            //margin-right: 6.5vh;
            font-family: IBM Plex Mono Bold;
            font-size: max(2.25vh, 16px);
            color: var(--darkgreen);
        }

        .discount{
            color: #e35a4f;
        }        
    }

    .total{
        width: 100%;
        //padding: 0 2vh;
        margin-top: 1vh;
        padding-top: 1vh;
        padding-bottom: 2.5vh;
        border-top: 1px solid rgba(68, 105, 97, 0.5);

        position: relative;

        .price{
            margin-right: 6vh;
            font-size: max(2.5vh, 18px);
            font-family: IBM Plex Mono Bold;
        }

        .coupon{
            position: absolute;
            right: 0;
            bottom: 0;

            display: flex;
            justify-content: center;
            align-items: center;

            cursor: pointer;
            
            p{
                margin-left: 0.5vh;
                text-transform: capitalize;
                text-decoration: underline;
                font-size: 1.5vh;
                color: black;
            }
        }        
    }

    .purchase{
        width: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .checkout{
            margin-top: 3vh;
            align-self: center;
            width: 100%;
            background-color: var(--darkgreen);
            padding: 1.5vh;
            outline: none;
            border: none;
            margin-bottom: 1.5vh;

            font-family: Druk Wide Bold;
            font-size: 24px;
            font-weight: 500;
            text-transform: uppercase;
            color: white;

            transition: 350ms background-color;

            &:hover{
                background-color: #5b8a7f;
                cursor: pointer;
            }
        }

        p{
            font-family: Druk Wide Bold;
            font-size: 18px;
            color: var(--darkgreen);
            text-transform: capitalize;
            text-decoration: underline;
            cursor: pointer;
        }
    }

    @media(max-width: ${MOBILE_BREAKPOINT}){
        .purchase .checkout{
            margin-top: 0;
        }
    }
`;

export default function PriceDetails({ checkout, onHandleClose })
{
    const checkouturl = "https://checkout.torontocannabisauthority.ca/checkouts/606230f5742cc300c23a26f3/"+checkout?.id;

    return(
        <Wrapper>
            <div className="section">
                <h3>Subtotal</h3>
                <p className="price">${( checkout?.priceSummary?.subtotal ? (checkout.priceSummary.subtotal / 100) : 0).toFixed(2)}</p>
            </div>

            <div className="section">
                <h3>Taxes</h3>
                <p className="price">${(checkout?.priceSummary?.taxes ? ((checkout.priceSummary.taxes ? checkout.priceSummary.taxes : 0) / 100) : 0).toFixed(2)}</p>
            </div>
                    
            <div className="section">
                <h3>Discount</h3>
                <p className="discount">- ${(checkout?.priceSummary?.discounts ? ((checkout.priceSummary.discounts ? checkout.priceSummary.discounts : 0) / 100) : 0).toFixed(2)}</p>
            </div>

            <div className="section total">
                <h3>TOTAL</h3>
                <p className="price">${(checkout?.priceSummary?.total ? ((checkout.priceSummary.total ? checkout.priceSummary.total : 0) / 100) : 0).toFixed(2)}</p>
            </div>

            <div className="purchase">
                <a href={checkouturl}>
                <button className="checkout">
                    Checkout
                </button>
                </a>

                <p onClick={onHandleClose}>
                    Continue Shopping
                </p>
            </div>
        </Wrapper>
    )
}