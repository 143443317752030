import {useQuery} from "@apollo/client"
import { GET_ALL_PRODUCTS } from "../queries/query"
import useLocalStorage from "./useLocalStorage"

export const useGetAllProducts = (options) => {
	const [currentRetailer, setCurrentRetailer] = useLocalStorage('dutchie-plus--retailer-id')

	const variables = {
		retailerId: currentRetailer,
		...options,
	}

	return useQuery(GET_ALL_PRODUCTS, {
		variables,
		fetchPolicy: "cache-first"
	})
}