import React, { createContext, useState } from "react"
import { useGetAllProducts } from "../hooks/useGetAllProducts"

export const FiltersContext = createContext()

export const FiltersProvider = ({ children }) => {

	const [filters, setFilters] = useState({
		brandId: undefined,
		strainType: undefined,
		weights: undefined,
		effects: undefined,
		potencyThc: undefined,
		potencyCbd: undefined,
		search: '',
		category: undefined,
		subcategory: undefined,
		sort: { direction: "DESC", key: "POPULAR" },
		pagination: {offset: 0, limit: 20},
	});
	const initialPrice = { min: 0, max: 300}
	const [price, setPrice] = useState(initialPrice)
	const { data, loading } = useGetAllProducts(filters);

	const onChangeBrand = (option) => {
		setFilters(prevFilters => ({
			...prevFilters,
			brandId: option === "clear" ? undefined : option?.value,
		}))
	}

	const onChangeStrainType = (value) => {
		setFilters(prevFilters => ({
			...prevFilters,
			strainType: prevFilters.strainType === value ? undefined : value,
		}))
	}

	const onChangeWeights = (value) => {
		setFilters((prevFilters) => {
			if (prevFilters.weights && prevFilters.weights.includes(value)) {
				const updatedWeights = prevFilters.weights.filter((item) => item !== value);
				return {
					...prevFilters,
					weights: updatedWeights.length > 0 ? updatedWeights : undefined,
				};
			} else {
				return {
					...prevFilters,
					weights: [...(prevFilters.weights || []), value],
				};
			}
		});
	};

	const onChangeEffects = (value) => {
		setFilters((prevFilters) => {
			if (prevFilters.effects && prevFilters.effects.includes(value)) {
				const updatedEffects = prevFilters.effects.filter((item) => item !== value);
				return {
					...prevFilters,
					effects: updatedEffects.length > 0 ? updatedEffects : undefined,
				};
			} else {
				return {
					...prevFilters,
					effects: [...(prevFilters.effects || []), value],
				};
			}
		});
	};

	const onChangeMinMaxThc = (values) => {
		const [min, max] = values;

		setFilters(prevState => ({
			...prevState, potencyThc: {min, max, unit: "PERCENTAGE"}
		}))
	}

	const onChangeMinMaxCbd = (values) => {
		const [min, max] = values;

		setFilters(prevState => ({
			...prevState, potencyCbd: {min, max, unit: "PERCENTAGE"}
		}))
	}

	const onChangePrice = (values) => {
		const [min, max] = values;

		setPrice({ min, max })
	}

	const onChangeCategories = ({category, subcategory}) => {

		setFilters(prevState => ({
			...prevState,
			subcategory,
			category,
		}))
	}

	const onSearch = (value) => {
		setFilters(prevState => ({
			...prevState,
			search: value
		}))
	}

	const onChangeSort = (obj) => {
		setFilters(prevState => ({
			...prevState,
			sort: obj
		}))
	}

	const onChangePagination = (obj) => {
		setFilters(prevState => ({
			...prevState,
			pagination: obj
		}))
	}

	const clearAllFilters = () => {
		setFilters(prevState => ({
			...prevState,
			brandId: undefined,
			strainType: undefined,
			weights: undefined,
			effects: undefined,
			potencyThc: undefined,
			potencyCbd: undefined,
			search: "",
		}))
		setPrice({ min: 0, max: 300 })
	}

	return (
		<FiltersContext.Provider
			value={{
				filters,
				onChangeBrand,
				onChangeStrainType,
				onChangeWeights,
				onChangeEffects,
				data,
				loading,
				clearAllFilters,
				onChangeMinMaxThc,
				onChangeMinMaxCbd,
				onChangePrice,
				price,
				onChangeCategories,
				onSearch,
				onChangeSort,
				onChangePagination,
			}}
		>
			{children}
		</FiltersContext.Provider>
	);
};
