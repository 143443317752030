import React, {useState} from 'react';
import {Link} from 'gatsby';

// import { WithSearch } from '@elastic/react-search-ui';

import {
    NavSubLink,
    NavSubItem,
    NavArrow,
    NavSubSubItem,
    NavSubSubMenu,
    NavLabel,
    NavLabelLink,
    NavLink
} from './styled';

export default function NavSubLiComp({
    menuToggle, slug, id, title, category, isCurrentCategory, isEducation=false
}){
    const [opensubmenu, setOpensubmenu] = useState(false);

    // const hasAnyResults = (facets, slug) => {
    //     return getResultCount(facets, slug) > 0
    // };
    
    // const getResultCount = (facets, slug) => {
    //     if (facets && facets.subcategory && facets.subcategory.length > 0) {
    //         const facet = facets.subcategory[0]
    //         if (facet.data) {
    //         const matches = facet.data.filter(x => x.value === slug)
    //         if (matches.length > 0)
    //             return matches[0].count
    //         }
    //
    //     }
    //
    //     return 0;
    // }

    return(
        <>
        {typeof window !== "undefined" &&
        // <WithSearch
        //     mapContextToProps={({ facets, filters, setFilter }) => ({ facets, filters, setFilter })}
        // >
        //     {({ facets, filters, setFilter }) => {
        //         return (
        //         isEducation ? (
        //             <NavSubItem isCurrent={false}>
        //                 <NavLabelLink
        //                     href={slug}
        //                     className="includemobile"
        //                     small={true}
        //                 >
        //                     <div>{title}</div>
        //                 </NavLabelLink>
        //             </NavSubItem>
        //         )
        //         :
        //         (<NavSubItem isCurrent={isCurrentCategory}>
        //
        //
        //             <NavLabelLink
        //                 href={slug}
        //                 className="desktop includemobile"
        //                 onClick={() => {
        //                     setOpensubmenu(!opensubmenu);
        //                 }}
        //             >
        //                 <div>{title}</div>
        //             </NavLabelLink>
        //
        //             {/* <NavSubSubMenu openmenu={opensubmenu}>
        //                 {category?.SubCategories?.map((subcategory) => {
        //                     const id = subcategory.id;
        //                     const title = subcategory.Name;
        //                     const slug = "/menu/"+category.Slug.toLowerCase()+"/"+subcategory.Slug.toLowerCase();
        //                     //const isCurrentCategory = pathname === slug;
        //                     return hasAnyResults(facets, subcategory.Slug) ? (
        //                         <NavSubSubItem key={id}>
        //                             <Link onClick={menuToggle} to={slug}>{title}</Link>
        //                         </NavSubSubItem>
        //                     ) : null
        //                 })}
        //             </NavSubSubMenu> */}
        //         </NavSubItem>
        //         )
        //         )
        //     }}
        // </WithSearch>

          <NavLabelLink
            href={slug}
            className="desktop includemobile"
            onClick={() => {
                setOpensubmenu(!opensubmenu);
            }}
          >
              <div>{title}</div>
          </NavLabelLink>
        }
        </>
    )
}