import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { tablet } from "../../../assets/styles/Responsive";

const Wrap = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

const Url = styled.a`
  padding: 5px;
  margin: 0 6px;
  text-decoration: none;
  transition: opacity .1s linear;

  &:hover {
    opacity: .7;
  }
`;

const Image = styled.img`
  display: block;

  @media (max-width: ${tablet}) {
    filter: brightness(0) invert(1);
  }
`;

const Social = ({ data, className, }) => (
  <Wrap className={className}>
    {data.map((item) => (
      <Url href={item.link} target="_blank" rel="noreferrer" key={item.id}>
        <Image src={item.icon.localFile.publicURL} width="100" height="100" alt={item.icon.name} />
      </Url>
    ))}
  </Wrap>
);

Social.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    link: PropTypes.string.isRequired,
    icon: PropTypes.shape({}).isRequired,
  })).isRequired,
};

export default Social;
