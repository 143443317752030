import React, { useEffect, createRef } from 'react';
import styled from 'styled-components';

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import { CheckoutContext } from '../../contexts/checkout-context';

import ProductList from './ProductList';
import PriceDetails from './PriceDetails';

import { NAV_HEIGHT_MOBILE, NAV_HEIGHT } from '../../styles/constants';


const Wrapper = styled.div`
    top: ${NAV_HEIGHT};
    right: 0;

    position: fixed;
    height: 100vh;

    visibility: ${props => props.show ? "visible" : "hidden"};
    opacity: ${props => props.show ? 1 : 0};

    transition: visibility 0.35s linear, opacity 0.35s linear;

    @media(max-width: 1000px){
        top: ${NAV_HEIGHT_MOBILE};
    }
    scrollbar-width: thin;
`

const BackgroundWrapper = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    z-index: 5;

    background-color: black;
    opacity: 0.4;

    height: 100%;
    width: 100vw;
    left: 0;
`;

const SideCartWrapper = styled.div`
    position: absolute;
    right: 0;
    z-index: 6;
    height: 100%;
    width: 520px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--lightgreen);

    overflow-x: hidden;
    scrollbar-width: thin;

    top: ${props => props.show ? '-100px' : '-150vh'};

    transition: 500ms top;

    @media(max-width: 1000px)
    {
        border-top: 1px solid var(--darkgreen);
        width: 100vw;
        top: -20px;
    }
`;

export default function TotalCart({ show, setShow })
{
    const cartRef = createRef();
    //disable scroll
    useEffect(() => {
        if (show) {
            document.getElementsByTagName('html')[0].style.overflowY = 'hidden';
            disableBodyScroll(cartRef.current);
        }
    }, [show]);

    //enable scroll
    useEffect(() => {
        if (!show) {
            enableBodyScroll(cartRef.current);
            document.getElementsByTagName('html')[0].style.overflowY = '';
        }
    }, [show]);

    const onHandleClose = (e) => {
        e.preventDefault();
        setShow(false);
    }

    var retailerObj = null;

    return(
        <CheckoutContext.Consumer>
            {({ checkout, removeFromCart, currentRetailer, retailerList }) => {
                if (retailerList && currentRetailer){
                    retailerObj = retailerList.filter((x) => x.id===currentRetailer)[0];
                }

                return(
                    <Wrapper show={show}>
                        <BackgroundWrapper onClick={onHandleClose}/>
                        <SideCartWrapper show={show}>
                            <ProductList products={checkout} onHandleClose={onHandleClose} removeFromCart={removeFromCart}/>
                            <PriceDetails checkout={checkout} onHandleClose={onHandleClose}/>
                            {/* <Location retailerObj={retailerObj}/> */}
                        </SideCartWrapper>
                    </Wrapper>
                )
            }}
            
        </CheckoutContext.Consumer>
    );

}