import * as React from "react";
import PropTypes from "prop-types";
// Components
import Container from "../../layouts/Container";
// UI
import Social from "../../UI/Social";
// Utils
import getSlug from '../../../utils/getSlug';
// Style
import {
  Footer,
  FooterTop,
  NavigationCol,
  FooterBottom,
  Copyright,
} from './styled';

const FooterTemplate = ({ data: { tca, information, categoriesTitle, categoriesPages, social, copyright } }) => {
  const navigation = categoriesPages.map((category) => {
    const banner = category.node.banner;
    return {
      url: getSlug(banner.title),
      id: banner.id,
      name: banner.title,
    }
  });
  const categoriesNavData = {
    title: categoriesTitle,
    navigation,
  };

  return (
    <Footer>
      <Container>
        <FooterTop>
          <NavigationCol data={tca} />
          <NavigationCol data={information} />
          <NavigationCol data={categoriesNavData} />
        </FooterTop>
        <FooterBottom>
          <Copyright>
            {copyright}
          </Copyright>
          <Social data={social} />
        </FooterBottom>
      </Container>
    </Footer>
  );
}

FooterTemplate.defaultProps = {
  data: PropTypes.shape({}).isRequired,
}

export default FooterTemplate;
