import React, { useEffect } from 'react'
import styled from 'styled-components';
import QuantitySelector from './QuantitySelector';

import { MdClear } from "react-icons/md";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;

    max-height: 37.5vh;
    color: var(--darkgreen);
    padding-bottom: 1.5vh;
    scrollbar-width: thin;

    .title{
        padding: 1.5vh 0;
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        font-size: 2.25vh;
        text-transform: capitalize;
        font-family: Druk Wide Bold;

        //padding-bottom: 1vh;
        border-bottom: ${props => props.containsItems ? "1px solid rgba(177, 178, 179, 0.5)" : "none"};;

        .icon{
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            height: 50px;
            width: 50px;
            svg{
                height: 50px;
                width: 50px;
            }
        }
    }

    @media(max-width: 1000px)
    {
        max-height: 40vh;

        .title{
            justify-content: center;
            position: relative;

            .icon{
                position: absolute;
                right: 0;

                height: 30px;
                width: 30px;
                svg{
                    height: 30px;
                    width: 30px;
                }
            }
        }
    }
`;

const StyledTable = styled.div`
    width: 100%;
    overflow-y: ${props => props.containsItems ? "auto" : "hidden"};
    overflow-x: hidden;
    scrollbar-width: thin;

    font-family: IBM Plex Mono Regular;

    scrollbar-width: thin;
    scrollbar-color: #A2C7B3 #C3D4CF;
    &::-webkit-scrollbar {
        width: 7px;
        background: #C3D4CF;
        //border-radius: 5px;
        transition: 250ms;
    }

    &::-webkit-scrollbar-thumb{
        background-color: #A2C7B3;
        //border-radius: 10px;

        &:hover{
            background-color: #89a194;
        }

        &:active{
            background-color: #59806b;
        }
    }

    table{
        width: 100%;
    }

    .header{
        opacity: 0.5;
        font-family: IBM Plex Mono Bold;
    }

    .remove{
        /* width: 25px; */
        white-space: nowrap;        

        .icon{
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            
            width: 30px;
            height: 30px;

            svg{
                height: 30px;
                width: 30px;
            }
        }
    }

    .field{
        max-width: 8.5vw;
        width: 100%;
        text-align: left;
        text-transform: uppercase;
        //color: rgba(23, 42, 46, 0.5);
        font-size: max(2vh, 16px);
    }

    .product{
        height: 10vh;
        width: 100%;

        .field{
            display: flex;
            align-items: center;
          
            .prod-name {
              font-size: 12px;
              text-transform: capitalize;
              font-weight: 600;
            }

            .fieldLabel{
                display: none;
            }

            .name{
                display: flex;
                align-items: center;
                
                .image{
                    display: flex;
                    justify-content: center;
                    align-items: center;


                    margin-right: 2vh;
                    padding: 0.5vh;
                    background-color: white;
                    height: 4.5vh;
                    width: 4.5vh;

                    min-height: 45px;
                    min-width: 45px;

                    img{
                        max-height: 100%;
                        max-width: 100%;
                    }
                }
            }

            .price{
                display: flex;
                align-items: center;
                max-width: 65%;
                font-weight: 600;
                color: var(--darkgreen);

                .old{
                    text-decoration: line-through;
                    font-size: 12px;
                    opacity: 0.5;
                    margin-left: 1vw;
                }

                p{
                    margin: 0;
                    font-size: max(2.5vh, 18px);
                }
            }
        }
    }

    @media(max-width: 1000px)
    {
        .header{
            display: none;
        }

        .product{
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;

            width: 100%; 
            height: auto;

            padding-bottom: 3vh;

            .field{
                position: relative;
                padding-top: 1.5vh;
                .fieldLabel{
                    position: absolute;
                    top: 0;
                }

                .name{
                    font-size: max(1.75vh, 16px);
                    font-weight: 500;
                    padding-right: 40px;
                    .image{
                        height: 50px;
                        width: 50px;
                    }   
                }
            }
            
        }

        .remove{
            position: absolute;
            top: 16.5%;
            right: 3vw;
            z-index: 3;
        }

        .main{
            background-color: white;
            padding: 10px;
            width: 100%;

            .field{
                padding-top: 0;
                width: 100%;
                max-width: none;
            }
        }

        .quantityelement{
            width: 20vw;
            .fieldlabel{
                display: none;
            }
        }

        .priceelement{
            margin-right: 50px;
            .fieldlabel{
                display: none;
            }
        }
    }
`;

export default function ProductList({ products, onHandleClose, removeFromCart }) {

  return(
      <Wrapper>
          <div className="title">
              <h3>Cart ({products?.items && products.items.length > 0 ? (products.items.map((item) => item.quantity).reduce((x, y) => x + y)) : '0'})</h3>
              <div className="icon" onClick={onHandleClose}>
                  <MdClear/>
              </div>
          </div>

          { products?.items?.length > 0 &&
              <StyledTable containsItems={products?.items?.length > 0}>
                  <table>
                      <tbody>
                          <tr className="header">
                              <td className="remove">
                              </td>
                              <td>
                                  <div className="field">
                                  Item
                                  </div>
                              </td>
                              <td>
                                  <div className="field">
                                  Quantity
                                  </div>
                              </td>
                              <td>
                                  <div className="field">
                                  Price
                                  </div>
                              </td>
                          </tr>

                          {products.items.map((item) => {
                            const currentVariant = item.product.variants.find(el => el.option === item.option)

                            return(
                                  <tr key={item.id} className="product">
                                      <td className="remove">
                                          <div className="icon" onClick={() => removeFromCart(item.id)}>
                                              <MdClear/>
                                          </div>
                                      </td>
                                      <td className="main">
                                          <div className="field">
                                              <div className="name">
                                                  <div className="image">
                                                      <img src={item.product.image} alt={item.product.name} loading="lazy"/>
                                                  </div>
                                                  <span className="prod-name">{item.product.name}</span>
                                              </div>
                                          </div>
                                      </td>
                                      <td className="quantityelement">
                                          <div className="field">
                                              <p className="fieldLabel">Quantity</p>
                                              <QuantitySelector item={item}/>
                                          </div>
                                      </td>
                                      <td className="priceelement">
                                          <div className="field">
                                              <p className="fieldLabel">Price</p>
                                              <div className="price">

                                                  {currentVariant.specialPriceRec ? (
                                                      <>
                                                          <p>${currentVariant.specialPriceRec.toFixed(2)}</p>
                                                          <p className="old">${(currentVariant.priceRec * item.quantity)?.toFixed(2)}</p>
                                                      </>
                                                  )
                                                  : (
                                                      <p>${(currentVariant.priceRec  * item.quantity).toFixed(2)}</p>
                                                  )
                                                  }
                                              </div>
                                          </div>
                                      </td>
                                  </tr>
                              )
                          })}
                      </tbody>
                  </table>
              </StyledTable>
          }
      </Wrapper>
  )
}