import { gql } from "@apollo/client"

export const GET_ALL_PRODUCTS = gql`
  query GetAllProducts(
    $category: Category
    $subcategory: Subcategory
	  $retailerId: ID!
	  $brandId: String
	  $effects: [Effects!]
	  $weights: [String!]
	  $strainType: StrainType
	  $potencyThc: PotencyRange
	  $potencyCbd: PotencyRange
	  $search: String
	  $sort: MenuSort
	  $pagination: Pagination
  ) {
    menu(
    retailerId: $retailerId
    pagination: $pagination
    sort: $sort
    filter: {
      category: $category,
      subcategory: $subcategory,
	    brandId: $brandId,
	    effects: $effects,
	    weights: $weights,
	    strainType: $strainType,
	    potencyThc: $potencyThc,
      potencyCbd: $potencyCbd,
      search: $search
     }
    ) {
      products {
        brand {
          id
          name
        }
        category
        description
        descriptionHtml
        id
        image
        slug
        images {
          id
          url
          label
          description
        }
        name
        posId
        potencyCbd {
          formatted
          range
          unit
        }
        potencyThc {
          formatted
          range
          unit
        }
        staffPick
        strainType
        subcategory
        variants {
          id
          option
          priceMed
          priceRec
          specialPriceMed
          specialPriceRec
          quantity
        }
      }
      productsCount
    }
  }
`;

export const GET_ALL_SECONDARY_FILTERS = gql`
  query BrandsQuery(
  $retailerId: ID!
	) {
		menu(
		retailerId: $retailerId
	) {
	brands {
		description
			id
			imageUrl
			name
			}
		}
	}
`;

export const GET_TOTAL_PRODUCT = gql`
		query GetProductData(
	  $retailerId: ID!
	  $productId: ID!
	) {
	  product(
	    retailerId: $retailerId
	    id: $productId
	  ) {
	    name
	    id
	    description
	    category
	    subcategory
	    posMetaData {
	      id
	      category
	    }
	    brand {name}
	    image
	    strainType
	    effects
	    variants {
	        id
	        option
	        priceMed
	        priceRec
	        specialPriceMed
	        specialPriceRec
	        quantity
	      }
	    slug
	    potencyCbd {
	        formatted
	        range
	        unit
	      }
	      potencyThc {
	        formatted
	        range
	        unit
	      }
	  }
	}
`