import styled, { css } from 'styled-components';
import Colors from '../../../assets/styles/Colors';
import { tablet } from "../../../assets/styles/Responsive";

const BurgerEl = styled.button`
  width: 40px;
  height: 35px;
  display: none;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  z-index: 5;
  @media (max-width: ${tablet}) {
    display: flex;
  }
`;

const BarsCss = css`
  width: 40px;
  height: 5px;
  margin: 0 0 5px;
  transition: 0.2s;
  background-color: ${({ isOpened }) => isOpened ? Colors.white : Colors.green};
`;

const Bar1 = styled.div`
  ${BarsCss};
  ${({ isOpened }) => isOpened ? 'transform: rotate(-45deg) translate(-9px, 9px);' : ''}
`;

const Bar2 = styled.div`
  ${BarsCss};
  ${({ isOpened }) => isOpened ? 'opacity: 0;' : ''}
`;

const Bar3 = styled.div`
  ${BarsCss};
  margin-bottom: 0;
  ${({ isOpened }) => isOpened ? 'transform: rotate(45deg) translate(-9px, -10px);' : ''}
`;

export {
  BurgerEl,
  Bar1,
  Bar2,
  Bar3,
}