import * as React from "react";
import PropTypes from "prop-types";
// Style
import {
  Wrapper,
  Head5,
  NavWrap,
  Nav,
  LinkItemWrap,
  LinkItem,
  Contact,
  ContactItem,
  ContactItemAddress,
} from './styled';

const Navigation = ({ data: { title, navigation, contacts }, className }) => {
  const hascontacts = contacts ? true : false;
  const hasnavigation = navigation ? true : false;
  return (
    <Wrapper className={className}>
      <Head5 value={title} />
      <NavWrap hascontacts={hascontacts}>
        <Nav hascontacts={hascontacts} hasnavigation={hasnavigation}>
          {
          hasnavigation ? 
          navigation.map((item, key) => (
            <LinkItemWrap hascontacts={hascontacts} key={key}>
              <LinkItem to={item.url} key={item.id} hascontacts={hascontacts}>{item.name}</LinkItem>
            </LinkItemWrap>
          ))
          : null
        }
        </Nav>
        {hascontacts ? (
          <Contact>
            <ContactItem href={`mailto:${contacts.email}`}>
              {contacts.email}
            </ContactItem>
            <ContactItem href={`to:${contacts.phone}`}>
              {contacts.phone}
            </ContactItem>

            <ContactItemAddress>
              {contacts.address}
            </ContactItemAddress>
          </Contact>
        ) : null}
      </NavWrap>
    </Wrapper>
  );
}

Navigation.defaultProps = {
  data: PropTypes.shape({}).isRequired,
}

export default Navigation;
