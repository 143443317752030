import React from 'react';
import PropTypes from 'prop-types';
// Css
import {
  BurgerEl,
  Bar1,
  Bar2,
  Bar3,
} from './styled';

const Burger = ({ clickHandler, isOpened }) => (
  <BurgerEl
    onClick={clickHandler}
    type="button"
  >
    <Bar1 isOpened={isOpened} />
    <Bar2 isOpened={isOpened} />
    <Bar3 isOpened={isOpened} />
  </BurgerEl>
);

Burger.propTypes = {
  clickHandler: PropTypes.func.isRequired,
};

export default Burger;
