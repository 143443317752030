// Components
import Navigation from "./components/navigation";
// Style
import styled from "styled-components";
import Colors from "../../../assets/styles/Colors";
import Fonts from "../../../assets/styles/Fonts";
import { tablet } from "../../../assets/styles/Responsive";

const Footer = styled.footer`
  padding: 40px 0 20px;
  background-color: ${Colors.green};

  @media (max-width: ${tablet}) {
    padding-bottom: 15px;
  }
`;

const FooterTop = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 60px;
`;

const NavigationCol = styled(Navigation)`
  width: 30%;
  padding-right: 50px;

  &:last-child {
    padding-right: 0;
  }

  @media (max-width: ${tablet}) {
    width: 100%;
    padding-right: 0;
    margin-bottom: 65px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const FooterBottom = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 25px;
  border-top: 1px solid ${Colors.white};
`;

const Copyright = styled.p`
  color: ${Colors.white};
  font-size: 10px;
  line-height: 13px;
  font-family: ${Fonts.IBMRegular};
`;

export {
  Footer,
  FooterTop,
  NavigationCol,
  FooterBottom,
  Copyright,
}