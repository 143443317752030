import * as React from "react"
import PropTypes from "prop-types"
import { StaticImage } from "gatsby-plugin-image"
// UI
import Burger from '../../UI/Burger';
// Utils
import getSlug from "../../../utils/getSlug";
import NavLiComp from "./NavLiComp";

import {
  Wrapper,
  Header,
  HeaderTop,
  Logo,
  NavWrap,
  Nav,
  Navigation,
  SocialWrap,
} from './styled';

import Cart from './Cart'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';


const HeaderTemplate = ({ data: { menu, social, absolute, categories, educcategories, openedMenu, menuToggle, pathname } }) => {
  const [submenuOpen, setSubmenuOpen] = React.useState(false)

  const menuRef = React.createRef();
  //disable scroll
  React.useEffect(() => {
      if (openedMenu) {
          document.getElementsByTagName('html')[0].style.overflowY = 'hidden';
          disableBodyScroll(menuRef.current);
      }
  }, [openedMenu]);

  //enable scroll
  React.useEffect(() => {
      if (!openedMenu) {
          enableBodyScroll(menuRef.current);
          document.getElementsByTagName('html')[0].style.overflowY = '';
      }
  }, [openedMenu]);

  return(
  <Wrapper ref={menuRef}>
    <Header absolute={absolute}>
      <HeaderTop isOpened={openedMenu}>
        <Logo
          to="/"
          isOpened={openedMenu}
          className="desktop"
        >
          <StaticImage src="../../../images/logo.svg" alt="logo" />
        </Logo>
        <Logo
          to="/"
          isOpened={openedMenu}
          className="mobile"
        >
          <StaticImage src="../../../images/logo_cropped.svg" alt="logo"/>
        </Logo>
        <Burger isOpened={openedMenu} clickHandler={menuToggle} />
      </HeaderTop>
      <NavWrap isOpened={openedMenu}>
        <Nav>
          <Navigation>
            {menu.map((navItem) => {
              const { url, id, label } = navItem;
              const isCurrent = (pathname[pathname.length - 1] === '/' ? pathname.slice(0 , -1) : pathname) === url;
              return (
                <NavLiComp 
                  url={url}
                  id={id}
                  label={label}
                  isCurrent={isCurrent}
                  menuToggle={menuToggle}
                  categories={categories}
                  educcategories={educcategories}
                  pathname={pathname}
                />
              );
            })}
          </Navigation>
        </Nav>
        {/* <SocialWrap data={social} /> */}
      </NavWrap>
      <Cart/>
    </Header>
  </Wrapper>
)}

HeaderTemplate.defaultProps = {
  data: PropTypes.arrayOf(PropTypes.shape({
    menu: PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
    social: PropTypes.shape({
      id: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      icon: PropTypes.shape({}).isRequired,
    }).isRequired,
  })).isRequired,
}

export default HeaderTemplate;
