import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { tablet } from "../../../assets/styles/Responsive";

const Container = ({ children, leftPadding }) => (
  <ContainerWrapper leftPadding={leftPadding}>
    {children}
  </ContainerWrapper>
);

Container.defaultProps = {
  leftPadding: false,
};

Container.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  leftPadding: PropTypes.bool,
};

export default Container;

const ContainerWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1370px;
  padding: ${props => props.leftPadding ? '0 15px 0 110px' : '0 15px' };

  @media (max-width: ${tablet}) {
    padding: 0 20px;
  }
`;


