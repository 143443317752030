import React, { useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import { CheckoutContext } from '../../../contexts/checkout-context';
import TotalCart from '../../cart/TotalCart';

const CartWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    min-width: 100px;
    width: fit-content;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 18px;
    border-radius: 10px;
    cursor: pointer;
    color: var(--darkgreen);
    font-family: Druk Wide Bold;

    transition: 250ms background-color;

    .cartitems{
        margin-left: 1vh;
        padding: 10px 15px;
        background: var(--darkgreen);
        color: white;
        z-index: -1;
    }

    @media(max-width: 991px){
        position: absolute;
        top: 20px;
        left: 0;
        right: 0;
        margin: 0 auto;
    }
`;

const Logo = styled.img`
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
`;

export default function Nav()
{
    const [showcart, setShowCart] = useState(false);

    const onClickCart = (e) => {
        e.preventDefault();
        setShowCart(!showcart);
    }

    return(
        <>
            <CartWrapper onClick={onClickCart}>
                <CheckoutContext.Consumer>
                    {({ checkout }) => (
                        <>
                            <div>
                                Cart
                            </div>
                            {(checkout && checkout.items.length > 0) && (
                                <div className="cartitems">
                                    {checkout.items.map((item) => item.quantity).reduce((x, y) => x + y)}
                                </div>
                            )}
                        </>
                    )}
                </CheckoutContext.Consumer>
            </CartWrapper>
            <TotalCart show={showcart} setShow={setShowCart}/>
        </>
    )
};
