import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// Fonts
import Fonts from '../../../../assets/styles/Fonts';

const Element = styled.h5`
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  font-family: ${Fonts.DrukBold};
`;

const H5 = ({
  value, className
}) => (
  <Element className={className}>
    {value}
  </Element>
);

H5.defaultProps = {
  value: '',
};

H5.propTypes = {
  value: PropTypes.string,
};

export default H5;
