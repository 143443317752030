import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
// Components
import FooterTemplate from './FooterTemplate';

const FooterHeader = () => {
  const {
    strapiFooterMenu: { 
      information, categoriesTitle, tca, social, copyright 
    },
    allStrapiCategories: {
      edges: categoriesPages
    }
  } = FooterQuery();

  return (
    <FooterTemplate data={{ information, categoriesTitle, categoriesPages, tca, social, copyright }} />
  );
}

const FooterQuery = () => {
  const data = useStaticQuery(
    graphql`
      query FooterQuery {
        strapiFooterMenu {
          information {
            title
            navigation {
              id
              name
              url
            }
          }
          categoriesTitle
          tca {
            
            contacts {
              address
              email
              phone
            }
          }
          copyright
          social {
            link
            id
            icon {
              localFile {
                publicURL
              }
              name
            }
          }
        }
        allStrapiCategories {
          edges {
            node {
              banner {
                title
                id
              }
            }
          }
        }
      }
    `,
  );

  return data;
}

export default FooterHeader;
