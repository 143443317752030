import React, { useCallback, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { useLocation } from '@reach/router'
// Components
import HeaderTemplate from './HeaderTemplate'
// Utils
import { useIsTablet } from '../../../utils/responsiveHelper'

const Header = ({ absolute = false }) => {
  const { menu, social, categories, educcategories } = HeaderQuery()
  const [openedMenu, setOpenedMenu] = useState(false)
  const isTablet = useIsTablet()
  const { pathname } = useLocation()

  const menuToggle = useCallback(() => {
    const { body } = document

    setOpenedMenu(active => {
      if (!active && isTablet) {
        body.style.overflow = 'hidden'
      } else {
        body.removeAttribute('style')
      }
      return !active
    })
    return false
  }, [setOpenedMenu, isTablet])

  return (
    <HeaderTemplate
      data={{
        menu,
        social,
        absolute,
        categories,
        educcategories,
        openedMenu,
        menuToggle,
        pathname,
      }}
    />
  )
}

const HeaderQuery = () => {
  const data = useStaticQuery(
    graphql`
      query HeaderQuery {
        strapiMainMenu {
          menu {
            id
            label
            url
          }
          social {
            id
            link
            icon {
              name
              localFile {
                publicURL
              }
            }
          }
        }
        storeCategories: strapiFilterSettings {
          Categories {
            id
            Slug
            Name
            MetadataTitle
            MetadataDescription
            SubCategories {
              Name
              Slug
              MetadataTitle
              MetadataDescription
            }
          }
        }
        educationCategories: allStrapiCategories {
          edges {
            node {
              id
              banner {
                title
              }
            }
          }
        }
      }
    `
  )

  const {
    strapiMainMenu: { menu, social },
    storeCategories: { Categories: categories },
    educationCategories: { edges: educcategories },
  } = data

  return { menu, social, categories, educcategories }
}

export default Header
