import React, { useContext, useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';

import { CheckoutContext } from '../../contexts/checkout-context';

import { FiPlus, FiMinus } from "react-icons/fi";
import debounce from 'lodash.debounce';


const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 100%;
    width: fit-content;

    button{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 16px;
        width: 16px;
        color: var(--darkgreen);
        border: 1px solid var(--darkgreen);

        transition: 250ms background-color;

        .icon{
            height: 95%;
            width: 95%;
        }

        &:hover{
            cursor: pointer;
            background-color: #e6e6e6;
        }

        &:disabled
        {
            background-color: rgba(0, 0, 0, 0.02);
            border: 1px solid #a7a8ab;
        }
    }

    .quantity{
        margin: 0 10px;
        font-size: max(2.5vh, 18px);
    }

    @media(max-width: 1000px)
    {
        button{
            height: 25px;
            width: 25px;
        }

        .quantity{
            margin-left: 3vw;
            margin-right: 3vw;
        }
    }
`;

export default function QuantitySelector({ item })
{
    const { updateQuantity } = useContext(CheckoutContext);

    const [quantity, setQuantity] = useState(item.quantity)

    useEffect(() => {
        setQuantity(item.quantity)
    }, [item])

    const onAddItemToCart = (e) => {
        e.preventDefault();
        //updateQuantity(item.id, quantity + 1);
        var q = quantity + 1;
        setQuantity(q);

        debouncedUpdateQuantity(q);
    }

    const removeItemFromCart = (e) => {
        e.preventDefault();
        //updateQuantity(item.id, quantity - 1);
        var q = quantity - 1;
        setQuantity(q);

        debouncedUpdateQuantity(q);
    }

    const debouncedUpdateQuantity = useCallback(
		debounce((nextValue) => {
            updateQuantity(item.id, nextValue)
        }, 
        250),
		[], // will be created only once initially
	);

    return(
        <Wrapper>
            <button onClick={removeItemFromCart} disabled={quantity <= 1}>
                <FiMinus className="icon"/>
            </button>

            <p className="quantity">
                {quantity}
            </p>

            <button onClick={onAddItemToCart}>
                <FiPlus className="icon"/>
            </button>
        </Wrapper>
    )

}