import React, {useState} from 'react';
import NavSubLiComp from './NavSubLiComp';
import {
    NavLi,
    NavSubMenu,
    NavSubLink,
    NavSubItem,
    NavItemWrap,
    NavLabel,
    NavLabelLink,
    NavLink,
    NavArrow,
    NavSubSubItem,
    NavSubSubMenu,
} from './styled';

//For creating education nav
import getEducationSlug from './getEducationSlug';

export default function NavLiComp({id, url, label, isCurrent, menuToggle, categories, educcategories, pathname}){
    const [openmenu, setOpenmenu] = useState(false)
    const [openeduc, setOpenEduc] = useState(false);
    const iscta = label==="SHOP";
    return(
        <NavLi key={id} openmenu={openmenu} openeduc={openeduc} cta={iscta}>
            {label.toLowerCase().indexOf('shop') !== -1 ? (
                <NavItemWrap 
                    openmenu={openmenu}
                >
                    <NavLabel
                        cta={iscta}
                        onClick={() => {
                            setOpenmenu(!openmenu);
                        }}
                    >
                        <div>{label}</div>
                        <NavArrow openmenu={openmenu}/>
                    </NavLabel>

                    <NavSubMenu openmenu={openmenu} >
                        {categories.map((category) => {
                            const id = category.id;
                            const title = category.Name;
                            const slug = "/menu/"+ category.Slug.toLowerCase();
                            const isCurrentCategory = pathname === slug;

                            return (
                                <NavSubLiComp 
                                    menuToggle={menuToggle}
                                    slug={slug}
                                    id={id}
                                    title={title}
                                    category={category}
                                    isCurrentCategory={isCurrentCategory}
                                />
                            );
                        })}
                    </NavSubMenu>
                </NavItemWrap>
            ) :
            label.toLowerCase().indexOf('education') !== -1 ? (
                <NavItemWrap 
                    openmenu={openeduc}
                >
                    <NavLabel
                        onClick={() => {
                            setOpenEduc(!openeduc);
                        }}
                    >
                        <div>{label}</div>
                        <NavArrow openmenu={openeduc}/>
                    </NavLabel>

                    <NavSubMenu openmenu={openeduc} >
                        {educcategories?.map((category) => {

                            const id = category.node.id;
                            const title = category.node.banner.title;
                            const slug = getEducationSlug(category.node.banner.title);

                            return (
                                <NavSubLiComp 
                                    menuToggle={menuToggle}
                                    slug={slug}
                                    id={id}
                                    title={title}
                                    category={category}
                                    isCurrentCategory={false}
                                    isEducation={true}
                                />
                            );
                        })}
                    </NavSubMenu>
                </NavItemWrap>
            )
            : (
            <NavLink onClick={menuToggle} to={url} isCurrent={isCurrent}>{label}</NavLink>
            )}
        </NavLi>
    )
}